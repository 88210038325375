import { createRoutine } from 'redux-saga-routines';
const DOCUMENT = 'GL_AUTH_';

const actions = {
  checkAuthorization: createRoutine(DOCUMENT + 'CHECK_AUTHORIZATION'),
  login: createRoutine(DOCUMENT + 'LOGIN'),
  logout: createRoutine(DOCUMENT + 'LOGOUT'),
  setUser: createRoutine(DOCUMENT + 'SET_USER'),
  getUser: createRoutine(DOCUMENT + 'GET_USER'),
  resendAccessCode: createRoutine(DOCUMENT + 'RESEND_ACCESS_CODE'),
  sendPwReset: createRoutine(DOCUMENT + 'SEND_PW_RESET'),
  registerUser: createRoutine(DOCUMENT + 'REGISTER_USER'),
  updateUserCommunicationSettings: createRoutine(
    DOCUMENT + 'UPDATE_USER_COMMUNICATION_SETTINGS',
  ),
  updateCurrentUserSecurity: createRoutine(
    DOCUMENT + 'UPDATE_CURRENT_USER_SECURITY',
  ),
};
export default actions;
