import { combineReducers } from 'redux';
import GL_App from './global/app/reducers';
import GL_Auth from './global/auth/reducers';
import ImmoApp from './immoapp/reducers';
import ImmoFonds from './immofonds/reducers';
import ImmoMove from './immomove/reducers';

export default combineReducers({
  GL_Auth,
  GL_App,
  ImmoApp,
  ImmoFonds,
  ImmoMove,
});
