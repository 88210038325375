import { ID } from 'definitions/constants-fe';
import { getView } from 'library/functionHelper';
import { getDefaultPath } from 'library/urlSync';
import actions from './actions';

const preKeys = getDefaultPath();

const initState = {
  rx_collapsed: window.innerWidth > 1220 ? false : true,
  rx_view: getView(window.innerWidth),
  rx_height: window.innerHeight,
  rx_current: preKeys,
  rx_modalStatus: ID.none,
  rx_notificationStatus: ID.none,
  rx_notificationData: null,
  rx_introPdf: {},
};
export default function appReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.toggleCollapsed.TRIGGER:
      return { ...state, rx_collapsed: !state.rx_collapsed };

    case actions.setModal.TRIGGER:
      return { ...state, rx_modalStatus: payload };

    case actions.toggleAll.TRIGGER:
      const view = getView(payload.width);
      const collapsed = view !== ID.desktop_view;
      return {
        ...state,
        rx_collapsed: collapsed,
        rx_view: view,
        rx_height: payload.height,
      };

    case actions.changeCurrent.TRIGGER:
      return { ...state, rx_current: payload };

    case actions.setNotification.TRIGGER:
      return {
        ...state,
        rx_notificationStatus: payload.status ?? null,
        rx_notificationData: payload.data ?? null,
      };

    case actions.getIntroductionPdf.SUCCESS:
      return {
        ...state,
        rx_introPdf: {
          data: payload.data,
          title: payload.title,
          status: ID.success,
        },
      };
    case actions.getIntroductionPdf.FAILURE:
      return {
        ...state,
        rx_introPdf: {
          status: ID.error,
        },
      };

    default:
      return state;
  }
}
