import Loader from 'components/Utility/loader';
import App from 'containers/global/App/App';
import ErrorScreen from 'containers/global/Page/500';
import { USER_ROLE } from 'definitions/constants-fe';
import { getUserRole } from 'library/localStorageHelper';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { PUBLIC_ROUTE, TOOL } from 'route.constants';
import RouterLocationChange from './RouterLocationChange';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);

const LoginPage = React.lazy(() => import('containers/global/Page/signin'));

export default function PublicRoutes() {
  // Strangely, using userAccount here, or the general GL_Auth object, leads to a crash in the login logic...
  const rx_isLoggedIn = useSelector((state) => state.GL_Auth.rx_isLoggedIn);
  const userRole = getUserRole();
  const isEmployee =
    userRole != null ? parseInt(userRole) >= USER_ROLE.janitor.value : false;
  const isTenantRestricted =
    userRole != null
      ? parseInt(userRole) === USER_ROLE.tenant_restricted.value
      : false;
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <ErrorScreen>
          <RouterLocationChange />
          <Switch>
            {/* The next Route can be removed in May 2021 */}
            <Route
              exact={true}
              path={`${PUBLIC_ROUTE.LOGIN}/${TOOL.IMMOMOVE}`}
              render={() => <Redirect to={`/${TOOL.IMMOMOVE}`} />}
            />
            <Route
              exact={true}
              path={PUBLIC_ROUTE.LANDING}
              render={() =>
                (window.location.href.includes(
                  process.env.REACT_APP_CLIENT_IMMOMOVE_URL,
                ) ||
                  isTenantRestricted) &&
                process.env.REACT_APP_CLIENT_IMMOMOVE_REDIRECT ===
                  String(true) ? (
                  <Redirect to={`/${TOOL.IMMOMOVE}`} />
                ) : (
                  <LoginPage />
                )
              }
            />
            <Route
              exact={true}
              path={`/${TOOL.IMMOMOVE}`}
              component={() => <LoginPage />}
            />
            <Route
              exact={true}
              path={PUBLIC_ROUTE.PDF_INTRODUCTION}
              component={React.lazy(() =>
                import('containers/global/Pdf/Introduction'),
              )}
            />
            <Route
              exact={true}
              path={PUBLIC_ROUTE.PAGE_403}
              component={React.lazy(() => import('containers/global/Page/403'))}
            />
            {/* User has to be logged in AND (immomove user OR employee) in order to access private pages. */}
            {rx_isLoggedIn &&
            (window.location.href.includes('immomove') || isEmployee) ? (
              <PrivateRoute component={App} />
            ) : (
              <Redirect
                to={{
                  pathname: PUBLIC_ROUTE.LANDING,
                }}
              />
            )}
          </Switch>
        </ErrorScreen>
      </BrowserRouter>
    </Suspense>
  );
}
