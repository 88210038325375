import { IFWizard } from 'assets/definitions/IFWizard';

// factor = based on type + subtype
// final cost is adjusted by GVZ, amount of apartment, immovable_type
// ACTUALLY NEEDED: apartments, ground_area
export const calculateComponentRenovationCost = (
  immovableData,
  componentData,
) => {
  let base = 0;
  switch (componentData.type) {
    case IFWizard.components.solar_collector.id:
    case IFWizard.components.water_softening_system.id:
    case IFWizard.components.fireplace.id:
      base = 1;
      break;
    default:
      base = immovableData.apartments;
      break;
  }
  // switch (componentData.type) {
  //   case IFWizard.components.facade.id:
  //     // Math.cbrt(cubature m³) gives equal length, height and depth = X
  //     // const x = Math.cbrt(immovableData.cubature_insureance_value);
  //     // X * X to get one side of building * 4 sides of building
  //     // base = x * x * 4;
  //     base = immovableData.apartments;
  //     break;
  //   case IFWizard.components.windows.id:
  //     // Amount of apartments * 5 windows each
  //     base = immovableData.apartments * 5;
  //     break;
  //   case IFWizard.components.elevator.id:
  //     // Amount of apartments / 5 (one elevator stop for every 5 apartments)
  //     base = immovableData.apartments / 5;
  //     break;

  //   case IFWizard.components.heater.id:
  //     // Every apartment * factor
  //     base = immovableData.apartments;
  //     break;
  //   case IFWizard.components.roof.id:
  //     // ground_area m² * factor
  //     base = immovableData.ground_area;
  //     break;
  //   default:
  //     break;S
  // }
  // Base * factor
  return (
    base *
    IFWizard.components[componentData.type].subtypes[componentData.subtype]
      .factor
  );
};
