import defaultTheme from './default';

const themes = {
  defaultTheme,
};

export const themeConfig = {
  topbar: 'defaultTheme',
  sidebar: 'defaultTheme',
  layout: 'defaultTheme',
  theme: 'defaultTheme',
};
export default themes;
