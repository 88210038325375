import { ReactComponent as document } from 'assets/images/document.svg';
import { ReactComponent as inbox } from 'assets/images/inbox.svg';
import { ReactComponent as mandate } from 'assets/images/mandate.svg';
import { ReactComponent as pin } from 'assets/images/pin.svg';
import { ReactComponent as home } from 'assets/images/simple-house.svg';
import { ReactComponent as user } from 'assets/images/user.svg';
import { ReactComponent as vote } from 'assets/images/vote.svg';
import { ReactComponent as wrench } from 'assets/images/wrench.svg';
import { USER_ROLE } from 'definitions/constants-fe';
import { getUserRole } from 'library/localStorageHelper';
import { PAGE, TOOL } from 'route.constants';

export const getOptions = () => {
  const options = [];
  options.push(
    {
      key: `${TOOL.IMMOAPP}/${PAGE.IA_DASHBOARD}`,
      label: 'global.home',
      icon: home,
      minRole: USER_ROLE.tenant_normal.value,
    },
    {
      key: `${TOOL.IMMOAPP}/${PAGE.IA_MESSAGES}`,
      label: 'global.messages',
      icon: inbox,
      minRole: USER_ROLE.tenant_normal.value,
    },
    {
      key: `${TOOL.IMMOAPP}/${PAGE.IA_REPAIRS}`,
      label: 'global.repairs',
      icon: wrench,
      minRole: USER_ROLE.tenant_normal.value,
    },
  );
  if (process.env.REACT_APP_IA_PINBOARD_ENABLED === 'true') {
    options.push({
      key: `${TOOL.IMMOAPP}/${PAGE.IA_PINBOARD}`,
      label: 'global.pinboard',
      icon: pin,
      minRole: USER_ROLE.tenant_normal.value,
    });
  }
  if (process.env.REACT_APP_IA_DOCUMENTS_ENABLED.includes(getUserRole())) {
    options.push({
      key: `${TOOL.IMMOAPP}/${PAGE.IA_DOCUMENTS}`,
      label: 'global.documents',
      icon: document,
      minRole: USER_ROLE.tenant_normal.value,
    });
  }
  if (
    process.env.REACT_APP_IA_VOTING_ENABLED === 'true' &&
    getUserRole() !== USER_ROLE.janitor.value.toString()
  ) {
    options.push({
      key: `${TOOL.IMMOAPP}/${PAGE.IA_VOTES}`,
      label: 'global.votes',
      icon: vote,
      minRole: USER_ROLE.owner.value,
    });
  }
  options.push(
    {
      key: `${TOOL.IMMOAPP}/${PAGE.IA_MANDATES}`,
      label: 'global.mandates',
      icon: mandate,
      minRole: USER_ROLE.manager.value,
    },
    {
      key: `${TOOL.IMMOAPP}/${PAGE.IA_USERS}`,
      label: 'global.user',
      icon: user,
      minRole: USER_ROLE.manager.value,
    },
  );
  return options;
};
