import { ID } from 'definitions/constants-fe';
import { showMessage } from 'library/functionHelper';
import actions from './actions';

const initState = {
  // Since we might have multiple operations at the same time, we need a state for all
  mLoading: [],
  rx_loading: false,
  rx_error: null,
  rx_showOverwriteBoxAndConfirm: null,
  rx_questionnaireSubmitted: false,
  rx_commonFiles: [],
  rx_terminationProcesses: [],
  rx_terminationProcessDetails: {},
  rx_userAndProcess: {},
  rx_voucherRequestedTime: null,
};

export default function reducer(state = initState, { type, payload }) {
  const typeName = type.split('/')[0];
  switch (type) {
    // GET TERMINATION PROCESSES REALTIME
    case actions.subscribeTerminationProcesses.TRIGGER:
      return {
        ...state,
      };
    case actions.subscribeTerminationProcesses.REQUEST:
      if (state.rx_terminationProcesses.length === 0) {
        state.mLoading.push(typeName);
      }
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeTerminationProcesses.SUCCESS:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_terminationProcesses: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeTerminationProcesses.FAILURE:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_error: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeTerminationProcesses.FULFILL:
      return {
        ...state,
        rx_error: null,
      };

    // GET TERMINATION PROCESS DETAILS
    case actions.getTerminationProcessDetails.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.getTerminationProcessDetails.SUCCESS:
      return {
        ...state,
        rx_terminationProcessDetails: payload,
      };
    case actions.getTerminationProcessDetails.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.getTerminationProcessDetails.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // GET COMMON FILES
    case actions.getCommonFiles.TRIGGER:
      if (state.rx_commonFiles.length === 0) {
        state.mLoading.push(typeName);
      }
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.getCommonFiles.SUCCESS:
      return {
        ...state,
        rx_commonFiles: payload,
      };
    case actions.getCommonFiles.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.getCommonFiles.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // TERMINATION ACTION
    case actions.terminationAction.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.terminationAction.REQUEST:
      return {
        ...state,
        rx_showOverwriteBoxAndConfirm: payload,
      };
    case actions.terminationAction.SUCCESS:
      showMessage(payload, ID.success);
      return {
        ...state,
      };
    case actions.terminationAction.FAILURE:
      showMessage(payload, ID.error);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.terminationAction.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_showOverwriteBoxAndConfirm: null,
        rx_error: null,
      };

    // UPDATE TERMINATION STATUS
    case actions.updateTerminationStatus.TRIGGER:
      return {
        ...state,
      };
    case actions.updateTerminationStatus.SUCCESS:
      return {
        ...state,
      };
    case actions.updateTerminationStatus.FAILURE:
      showMessage(payload, ID.error);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.updateTerminationStatus.FULFILL:
      return {
        ...state,
        rx_error: null,
      };

    // RESEND ACCESS
    case actions.resendAccess.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.resendAccess.SUCCESS:
      showMessage(payload, ID.success);
      return {
        ...state,
      };
    case actions.resendAccess.FAILURE:
      showMessage(payload, ID.error);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.resendAccess.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // SEND VOUCHER
    case actions.sendVoucher.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.sendVoucher.SUCCESS:
      return {
        ...state,
        rx_voucherRequestedTime: payload,
      };
    case actions.sendVoucher.FAILURE:
      showMessage(payload, ID.error);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.sendVoucher.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // SAVE ANSWERS
    case actions.saveAnswers.TRIGGER:
      if (payload.submit === true) {
        state.mLoading.push(typeName);
      }
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.saveAnswers.SUCCESS:
      return {
        ...state,
        rx_questionnaireSubmitted: payload,
      };
    case actions.saveAnswers.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.saveAnswers.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_questionnaireSubmitted: false,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // GET USER AND PROCESS
    case actions.getUserAndProcess.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.getUserAndProcess.SUCCESS:
      return {
        ...state,
        rx_userAndProcess: payload,
      };
    case actions.getUserAndProcess.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.getUserAndProcess.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    default:
      return state;
  }
}
