import { all } from 'redux-saga/effects';
import GL_App from './global/app/sagas';
import GL_Auth from './global/auth/sagas';
import ImmoApp from './immoapp/sagas';
import ImmoFonds from './immofonds/sagas';
import ImmoMove from './immomove/sagas';

export default function* rootSaga(_: any) {
  yield all([GL_App(), GL_Auth(), ImmoApp(), ImmoFonds(), ImmoMove()]);
}
