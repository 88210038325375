/**
 * React-Intl middleware
 * https://formatjs.io/docs/react-intl/api/
 */

import { DEFAULT_LANGUAGE_ID } from 'config/language.config';
import { AppLocale } from 'config/translation';
import { createIntl, createIntlCache, useIntl } from 'react-intl';

let cache = createIntlCache();

/**
 * The intl object `IntlShape`.
 * More flexible than using `IntlMessages`.
 */
export let intl = createIntl(
  {
    locale: AppLocale[DEFAULT_LANGUAGE_ID].locale,
    // Note: createIntl does not allow the structure we use for ImmoApp translations ---> { "none": "Keine Ergebnisse","singular": "{{count}} Ergebnis", "plural": "{{count}} Ergebnisse" }
    // However, this is not a real problem, so we just ignore it.
    // @ts-ignore
    messages: AppLocale[DEFAULT_LANGUAGE_ID].messages,
  },
  cache,
);

/**
 * Generate IntlShape object
 * @param {Object} props
 * @param {String} props.locale - User specified language
 * @param {Object} props.messages - Messages
 * @returns {Object}
 */
export const generateIntl = (props: any) => {
  cache = createIntlCache();
  intl = createIntl(props, cache);
  return intl;
};

/**
 * A `React.Component` with the translated text.
 */
export const IntlMessages = (props: {
  id: string;
  values?: Record<string, any>;
}): any => {
  const intl = useIntl();
  return <>{intl.formatMessage({ id: props.id }, props.values)}</>;
};
