export const PUBLIC_ROUTE = {
  LANDING: '/',
  PDF_INTRODUCTION: '/pdf-intro',
  PAGE_403: '/403',
};

export const TOOL = {
  ACCOUNT: 'account',
  STATISTICS: 'statistics',
  TEAM: 'team',
  SETTINGS: 'settings',
  IMMOAPP: 'immoapp',
  IMMOFONDS: 'immofonds',
  IMMOMOVE: 'immomove',
};

export const PAGE = {
  IA_DASHBOARD: 'dashboard',
  IA_MANDATES: 'mandates',
  IA_MESSAGES: 'messages',
  IA_REPAIRS: 'tickets',
  IA_PINBOARD: 'pinboard',
  IA_VOTES: 'votes',
  IA_VOTES_RESULTS: 'voteResults',
  IA_USERS: 'users',
  IA_DOCUMENTS: 'documents',
  IF_HOME: 'home',
  IF_IMMOVABLE: 'immovable',
  IF_COMPONENT: 'component',
  IM_PROCESSES: 'processes',
  IM_START: 'start',
  IM_QUESTIONNAIRE: 'questionnaire',
  IM_END: 'end',
};
