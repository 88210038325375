/**
 * Class for offline query.
 *
 * @class      QueryHelper (name)
 */
class QueryHelper {
  /**
   * offline query to get records according to data
   *
   * @param      {array}  records      The records
   * @param      {string}  parentKey    The parent key
   * @param      {string}  comparison  The comparison
   */
  queryHelp(records = [], parentKey = '', comparison = '') {
    return records.filter((record) => {
      return record[parentKey] === comparison;
    });
  }

  /**
   * Queries a first.
   *
   * @param      {<type>}  records      The records
   * @param      {string}  parentKey    The parent key
   * @param      {string}  comparison  The comparison
   * @return     {Object}               First record of filter
   */
  queryFirst(records = [], parentKey = '', comparison = '') {
    const response = records.filter((record) => {
      return record[parentKey] === comparison;
    });
    return response.length > 0 ? response[0] : {};
  }
}

export default new QueryHelper();
