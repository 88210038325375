import { ID } from 'definitions/constants-fe';
import { showMessage } from 'library/functionHelper';
import actions from './actions';

const initState = {
  // Since we might have multiple operations at the same time, we need a state for all
  mLoading: [],
  rx_loading: false,
  rx_error: null,

  rx_immovables: [],
  rx_editingArea: ID.none,
  rx_pdfData: null,
  rx_componentDetails: null,
};

export default function reducer(state = initState, { type, payload }) {
  const typeName = type.split('/')[0];
  switch (type) {
    // GET IMMOVABLE REALTIME
    case actions.subscribeImmovables.TRIGGER:
      return {
        ...state,
      };
    case actions.subscribeImmovables.REQUEST:
      if (state.rx_immovables.length === 0) {
        state.mLoading.push(typeName);
      }
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeImmovables.SUCCESS:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_immovables: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeImmovables.FAILURE:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_error: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeImmovables.FULFILL:
      return {
        ...state,
        rx_error: null,
      };

    // GET IMMOVABLE DETAILS
    case actions.getImmovableDetails.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.getImmovableDetails.SUCCESS:
      return {
        ...state,
        rx_immovableDetails: payload,
      };
    case actions.getImmovableDetails.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.getImmovableDetails.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // GET COMPONENT DETAILS
    case actions.getComponentDetails.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.getComponentDetails.SUCCESS:
      return {
        ...state,
        rx_componentDetails: payload,
      };
    case actions.getComponentDetails.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.getComponentDetails.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // IMMOVABLE ACTION
    case actions.immovableAction.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.immovableAction.SUCCESS:
      showMessage(payload, ID.success);
      return {
        ...state,
      };
    case actions.immovableAction.FAILURE:
      showMessage(payload, ID.error);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.immovableAction.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // BANK ACCOUNT ACTION
    case actions.bankAccountAction.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.bankAccountAction.SUCCESS:
      showMessage(payload, ID.success);
      return {
        ...state,
      };
    case actions.bankAccountAction.FAILURE:
      showMessage(payload, ID.error);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.bankAccountAction.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // COMPONENT ACTION
    case actions.componentAction.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.componentAction.SUCCESS:
      showMessage(payload, ID.success);
      return {
        ...state,
      };
    case actions.componentAction.FAILURE:
      showMessage(payload, ID.error);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.componentAction.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // DOWNLOAD PDF
    case actions.downloadPdf.TRIGGER:
      return {
        ...state,
      };
    case actions.downloadPdf.SUCCESS:
      return {
        ...state,
        rx_pdfData: payload,
      };
    case actions.downloadPdf.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.downloadPdf.FULFILL:
      return {
        ...state,
        rx_pdfData: null,
        rx_error: null,
      };

    // UPDATE EDITING AREA (ONLY TRIGGER NEEDED)
    case actions.updateEditingArea.TRIGGER:
      return {
        ...state,
        rx_editingArea: payload,
      };

    default:
      return state;
  }
}
