import { createRoutine } from 'redux-saga-routines';

const DOCUMENT = 'IF_';
const actions = {
  // --- START REALTIME ---
  subscribeImmovables: createRoutine(DOCUMENT + 'SUBSCRIBE_IMMOVABLES'),
  cancelImmovables: createRoutine(DOCUMENT + 'CANCEL_IMMOVABLES'),
  // TODO missing cancel

  // --- END REALTIME ---
  getImmovableDetails: createRoutine(DOCUMENT + 'GET_IMMOVABLE_DETAILS'),
  getComponentDetails: createRoutine(DOCUMENT + 'GET_COMPONENT_DETAILS'),
  immovableAction: createRoutine(DOCUMENT + 'IMMOVABLE_ACTION'),
  bankAccountAction: createRoutine(DOCUMENT + 'BANK_ACCOUNT_ACTION'),
  componentAction: createRoutine(DOCUMENT + 'COMPONENT_ACTION'),
  downloadPdf: createRoutine(DOCUMENT + 'DOWNLOAD_PDF'),
  updateEditingArea: createRoutine(DOCUMENT + 'UPDATE_EDITING_AREA'),
};
export default actions;
